<template>
  <div class="about">
    <!-- <navi-gation /> -->
    <div class="logoImg" style="margin-left: 60px; margin-top: 25px">
      <img :src="aboutImgConfig" alt="" style="height:48px;" />
    </div>
    <div style="margin-left: 60px" class="contact_wrap">
      <div>
        <b> {{ $t("System.AboutPage.productName") }}</b><span>{{ descriptions.platformName }}</span>
      </div>
      <div>
        <b> {{ $t("System.AboutPage.productVersion") }}</b><span> {{ descriptions.version }}</span>
      </div>
      <div>
        <b>{{ $t("System.AboutPage.customer") }}</b><span>{{ descriptions.client }}</span>
      </div>
      <div style="margin-left: 16px;display: flex;">
        <b>{{ $t("System.AboutPage.NumberUsers") }}</b>
        <span v-if="descriptions.users == 0">{{ $t("System.AboutPage.unlimited") }}</span>
        <span v-else-if="descriptions.users == -1">{{ $t("System.AboutPage.unauthorized") }}</span>
        <span v-else>{{ descriptions.users }}<span style="margin-left: 10px">{{ $t("System.AboutPage.residue") }}</span>{{ descriptions.leftUsers }}{{ $t("System.AboutPage.individual") }}</span>
      </div>
      <div style="margin-left: 16px">
        <b>{{ $t("System.AboutPage.NumberDevices") }}</b>
        <span v-if="descriptions.devices == 0">{{ $t("System.AboutPage.unlimited") }}</span>
        <span v-else-if="descriptions.devices == -1">{{ $t("System.AboutPage.unauthorized") }}</span>
        <span v-else>{{ descriptions.devices }}<span style="margin-left: 10px">{{ $t("System.AboutPage.residue") }}</span>{{ descriptions.leftDevices }}{{ $t("System.AboutPage.individual") }}</span>
      </div>
      <div>
        <b> {{ $t("System.AboutPage.SequenceNumber") }}</b><span>{{ sn }}</span>
      </div>
      <div>
        <b>{{ $t("System.AboutPage.serviceLife") }}</b><span>{{ descriptions.endDay }}</span>
        <span style="margin-left: 10px">{{ $t("System.AboutPage.residue") }}{{ descriptions.leftDays }}{{ $t("System.AboutPage.day") }}</span>
      </div>
      <div>
        <b>{{ $t("System.AboutPage.ServicePeriod") }}</b><span>{{ descriptions.endServiceDay }}</span>
        <span style="margin-left: 10px">{{ $t("System.AboutPage.residue") }}{{ descriptions.leftServiceDays }}{{ $t("System.AboutPage.day") }}</span>
      </div>
      <div>
        <b> {{ $t("System.AboutPage.Copyright") }}</b><span>{{this.copyText}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import naviGation from '@/components/hearder/index'
export default {
  components: { naviGation },
  data() {
    return {
      copyText: infoConfig.copyright_page, //页面版权归属
      // year: new Date().getFullYear(),
      descriptions: [],
      sn: '',
      aboutImgConfig:infoConfig.aboutImgConfig, //关于页面图片配置
    }
  },
  mounted() {
    this.aboutInfo()
    this.getSn() //获取序列号码
  },
  methods: {
    async aboutInfo() {
      var params = {}
      const res = await this.$axios.post('/httpServe/about/info', params, true)
      // debugger
      this.descriptions = res.data
      console.log(res.data, '关于信息')
    },
    //获取序列号码
    async getSn() {
      var params = {}
      const res = await this.$axios.post(
        '/httpServe/license/selectOne',
        params,
        true
      )
      console.log(res, '获取授权信息')
      this.sn = res.data.sn
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  .contact_wrap {
    div {
      margin-bottom: 20px;
      b {
        font-size: 15px;
      }
      span {
        font-size: 14px;
        color: rgb(119, 119, 119);
      }
    }
  }
}
</style>