<template>
  <el-row :gutter="5">
    <!-- <el-col :span="24">
        <div class="text-hearder"> <span class="gang-color">|</span> <span class="text-color">{{$route.meta.title}}</span> </div>
        <el-divider></el-divider>
    </el-col> -->
  </el-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 0 0 0 10px;
}
.text-hearder {
  margin: 10px 0 8px 10px;
}
.gang-color {
  color: #1e89e5;
  font-size: 20px;
  font-weight: bold;
}
.text-color {
  color: #6d7a82;
  font-weight: bold;
}
</style>
